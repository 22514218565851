exports.file = {
  serialize: ({
    type,
    asyncData
  }) => ({
    file: {
      response: value
    } = {}
  } = {}) => ({
    value,
    type,
    asyncData
  }),
  deserialize: ({
    value
  } = {}) => value
};