import { useMemo } from 'react'
import { useBindingField } from '../../hooks/useBindingField'
import classNames from 'classnames'
import { TypeField } from './TypeField'
import { InfoIcon } from '../../components'

interface TypeFieldCheckBoxProps {
  icon?: string
  definition: any
  name: string
  [key: string]: any // Allow additional props
}

export const TypeFieldCheckBox: React.FC<TypeFieldCheckBoxProps> = ({
  icon = 'information',
  ...params
}) => {
  const { input } = useBindingField(params)

  const finalIcon = useMemo(() => {
    if (icon === 'information') {
      return (
        <div className="form-runner-form-field-checkbox-icon">
          <InfoIcon />
        </div>
      )
    }
    return null
  }, [icon])

  return (
    <div
      className={classNames(
        'form-runner-form-field-checkbox',
        input.checked && 'form-runner-form-field-checkbox--checked',
        icon === 'information' && 'form-runner-form-field-checkbox--custom'
      )}
    >
      <TypeField {...params} />
      {finalIcon}
    </div>
  )
}
