import React, { useCallback, useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { v4 as uuid } from 'uuid'
import {
  uploadDocumentMutation,
  replaceDocumentMutation,
} from '../../graphql/mutations/document'
import { getDocumentByIdQuery } from './graphql/queries'

import { handleFieldWithoutPrefix } from './helpers'
import { getPreviewLink } from '../../helpers/document'
import { FieldView } from '../FieldView'
import { useBindingField } from '../../hooks/useBindingField'
import { messageError } from '../../components/Message'

interface TypeFieldUploadProps {
  name: string
  fileLanguage?: string
  stepId?: string
  signer?: string
  preview?: boolean
  processInstance?: { id: string }
  filetypeId?: string
  tags?: string[]
  signaturePosition?: string
  signatureType?: string
  signatureAnchorIgnoreIfNotPresent?: boolean
  signatureAnchorString?: string
  signatureAnchorOffset?: number
  definition: any
}

export const TypeFieldUpload: React.FC<TypeFieldUploadProps> = params => {
  const [uploadDocument] = useMutation(uploadDocumentMutation)
  const [replaceDocument] = useMutation(replaceDocumentMutation)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const { input, meta } = useBindingField(params)
  const [documentIdInitialValue, setDocumentIdInitialValue] = useState<
    string | null
  >(input?.value || null)

  const {
    name,
    fileLanguage,
    stepId,
    signer,
    preview,
    processInstance,
    filetypeId,
    tags,
    signaturePosition,
    signatureType,
    signatureAnchorIgnoreIfNotPresent,
    signatureAnchorString,
    signatureAnchorOffset,
  } = params

  const processInstanceId = processInstance?.id
  const filename = handleFieldWithoutPrefix(params)

  const metadata = useMemo(() => {
    return {
      fieldName: name,
      fileLanguage,
      filename,
      processInstanceId,
      signatureAnchorIgnoreIfNotPresent,
      signatureAnchorOffset,
      signatureAnchorString,
      signaturePosition,
      signatureType,
      signer,
      processInstanceStepId: stepId,
    }
  }, [
    filename,
    name,
    fileLanguage,
    processInstanceId,
    signatureAnchorIgnoreIfNotPresent,
    signatureAnchorOffset,
    signatureAnchorString,
    signaturePosition,
    signatureType,
    signer,
    stepId,
  ])

  const { data: { getDocumentById: document } = {} } = useQuery(
    getDocumentByIdQuery,
    {
      fetchPolicy: 'cache-and-network',
      skip: !documentIdInitialValue,
      variables: {
        documentId: documentIdInitialValue,
      },
    }
  )

  const customRequest = useCallback(
    async ({
      file,
      onSuccess,
    }: {
      file: File
      onSuccess: (id: string) => void
    }) => {
      try {
        setSubmitting(true)
        if (documentIdInitialValue && document) {
          const {
            data: {
              replaceDocument: { id },
            },
          } = await (preview
            ? Promise.resolve({ data: { replaceDocument: { id: uuid() } } })
            : replaceDocument({
                variables: {
                  documentSource: { processInstanceId },
                  file,
                  id: documentIdInitialValue,
                },
              }))
          onSuccess(id)
          setDocumentIdInitialValue(id)
        } else {
          const {
            data: {
              uploadDocuments: [{ id }],
            },
          } = await (preview
            ? Promise.resolve({ data: { uploadDocuments: [{ id: uuid() }] } })
            : uploadDocument({
                variables: {
                  documentSource: { processInstanceId },
                  file,
                  filetypeId,
                  flags: ['form'],
                  metadata,
                  tags,
                },
              }))
          onSuccess(id)
          setDocumentIdInitialValue(id)
        }
      } catch (e) {
        messageError('Error while uploading', e)
      }

      setSubmitting(false)
    },
    [
      filetypeId,
      metadata,
      preview,
      processInstanceId,
      tags,
      uploadDocument,
      document,
      replaceDocument,
      documentIdInitialValue,
    ]
  )

  return (
    <FieldView
      {...{
        meta,
        input,
        submitting,
        customRequest,
        getPreviewLink,
        ...params,
        document,
      }}
    />
  )
}
