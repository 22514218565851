import jmespath from 'jmespath'
import { flattenDeep, get } from 'lodash'
import { getDataValue } from '../helpers/utils/getDataValue'

interface Condition {
  key: string | string[]
  op: string
  value: any | any[]
  keyPath?: string
  condition?: Condition
}

interface SanitizedCondition {
  condition?: Condition
  key: string
  keyPath?: string
  op: string
  value: any
}

/**
 * Sanitizes a condition object by ensuring all properties are singular values (not arrays).
 * If any property is an array, the first element of the array is used.
 *
 * @param {Condition} condition - The condition object to sanitize.
 * @returns {SanitizedCondition} - The sanitized condition object.
 */
const sanitizeCondition = ({
  key,
  op,
  value,
  keyPath,
  condition,
}: Condition): SanitizedCondition => {
  if (Array.isArray(key)) {
    return {
      condition,
      key: key[0],
      keyPath: keyPath?.[0],
      op: op[0],
      value: value[0],
    }
  }

  return { condition, key, keyPath, op, value }
}

/**
 * Retrieves a value from a nested object using a dot-separated key path.
 * @param state - The state object to search.
 * @param keypath - The dot-separated key path.
 * @returns The value at the specified key path, or null if not found.
 */
const getPathValue = (state: any, keypath: string): any => {
  try {
    const keys = keypath.split('.')
    return keys.reduce((previous, current) => previous?.[current], state)
  } catch {
    return null
  }
}

/**
 * Checks if a condition is fulfilled based on the provided state.
 * @param condition - The condition to evaluate.
 * @param state - The state object to evaluate against.
 * @returns Whether the condition is fulfilled.
 */
export const conditionIsFullfilled = (
  condition: Condition,
  state: any
): boolean => {
  const checkValue = (
    value: any,
    exceptedValue: any,
    op: string,
    subCondition: Condition[]
  ): boolean => {
    switch (op) {
      case 'eq':
      case 'ne': {
        const result = Array.isArray(value)
          ? JSON.stringify(value.sort()) ===
            JSON.stringify(exceptedValue.sort())
          : value === exceptedValue

        return op === 'ne' ? !result : result
      }
      case 'inc':
      case 'ninc': {
        const result = (value || []).includes(exceptedValue)
        return op === 'ninc' ? !result : result
      }
      case 'lt':
        return value > exceptedValue
      case 'le':
        return value >= exceptedValue
      case 'ge':
        return value <= exceptedValue
      case 'gt':
        return value < exceptedValue
      case 'jump':
        return true
      case 'leq':
        return (exceptedValue || []).includes(value)
      case 'lne':
        return !(exceptedValue || []).includes(value)
      case 'and':
        if (!subCondition.length) {
          return true
        }
        return subCondition.every(cond => conditionIsFullfilled(cond, state))
      case 'or':
        if (!subCondition.length) {
          return true
        }
        return subCondition.some(cond => conditionIsFullfilled(cond, state))
      case 'not':
        return !subCondition.every(cond => conditionIsFullfilled(cond, state))
      case 'isSet':
      case 'isNotSet': {
        const isSet = exceptedValue !== null && exceptedValue !== undefined
        return op === 'isSet' ? isSet : !isSet
      }
      default:
        return true
    }
  }

  if (!condition) {
    return true
  }

  const {
    key,
    op,
    value,
    keyPath,
    condition: subCondition = [],
  } = sanitizeCondition(condition)

  const exceptedValue = getDataValue(
    keyPath ? getPathValue(state, keyPath) : key && jmespath.search(state, key)
  )

  if (Array.isArray(exceptedValue)) {
    return flattenDeep(exceptedValue).some(item =>
      checkValue(
        value,
        getDataValue(item),
        op,
        Array.isArray(subCondition) ? subCondition : [subCondition]
      )
    )
  }
  return checkValue(
    value,
    exceptedValue,
    op,
    Array.isArray(subCondition) ? subCondition : [subCondition]
  )
}

/**
 * Checks if all fields are filled based on their conditions.
 * @param fields - The fields to check.
 * @param state - The state object to evaluate against.
 * @returns Whether all fields are filled.
 */
const isFilled = (fields: any[], state: any): boolean =>
  fields.every(field => {
    if (!field.condition) {
      return true
    }

    if (
      field.condition &&
      field.condition.length &&
      !field.condition.every((cond: Condition) =>
        conditionIsFullfilled(cond, state)
      )
    ) {
      return true
    }

    if (field.type !== 'multiple') {
      return !field.required || !!state[field.name]
    }

    if (field.required && (!state[field.name] || !state[field.name].length)) {
      return false
    }

    return (state[field.name] || []).every((stateItem: any) =>
      isFilled(field.fields, stateItem || {})
    )
  })

/**
 * Collects frames based on conditions and state.
 * @param frames - The frames to process.
 * @param state - The state object to evaluate against.
 * @param result - The accumulated result.
 * @returns The collected frames.
 */
export const collectFrames = (
  frames: any[],
  state: any,
  result: any[] = []
): any[] => {
  if (!frames.length) {
    return result
  }

  const [frame, ...otherFrames] = frames
  result.push(frame)
  if (!isFilled(frame.fields, state)) {
    return result
  }

  if (!frame.condition || !frame.condition.length) {
    return collectFrames(otherFrames, state, result)
  }

  for (const condition of frame.condition) {
    if (conditionIsFullfilled(condition, state)) {
      const index = otherFrames.findIndex(
        frame => frame.id === condition.target
      )
      if (index > -1) {
        return collectFrames(otherFrames.slice(index), state, result)
      }
    }
  }

  return collectFrames(otherFrames, state, result)
}

/**
 * Gets the next frame based on the current frame ID and state.
 * @param config - The configuration object.
 * @param frameId - The current frame ID.
 * @param state - The state object to evaluate against.
 * @returns The next frame.
 */
export const getNextFrame = (
  { config }: { config: any[] },
  frameId: string,
  state: any = {}
): any => {
  const index = config.findIndex(({ id }) => frameId === id)
  return index >= 0 ? collectFrames(config.slice(index), state)[1] : undefined
}

type KeyMap = {
  [key: string]: string
}

/**
 * Replaces keys in an object or array based on a key map.
 * @param obj - The object or array to process.
 * @param keyMap - The key map for replacements.
 * @returns The object or array with replaced keys.
 */
function replaceKeys<T extends object | any[]>(obj: T, keyMap: KeyMap): T {
  if (Array.isArray(obj)) {
    return obj.map(item => replaceKeys(item, keyMap)) as T
  }

  if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).reduce(
      (acc, key) => {
        const newKey = keyMap[key] || key
        ;(acc as Record<string, any>)[newKey] = replaceKeys(
          (obj as Record<string, any>)[key],
          keyMap
        )
        return acc
      },
      {} as Record<string, any>
    ) as T
  }

  return obj
}

/**
 * Replaces condition values based on the provided data.
 * @param condition - The condition to process.
 * @param data - The data object to use for replacements.
 * @returns The condition with replaced values.
 */
function replaceConditionValues(condition: any, data: any): any {
  if (Array.isArray(condition)) {
    return condition.map(item => replaceConditionValues(item, data))
  }

  if (typeof condition === 'object' && condition !== null) {
    return Object.keys(condition).reduce(
      (acc, key) => {
        if (key === 'conditionValue' && typeof condition[key] === 'string') {
          const dataKey = condition[key]
          acc[key] = data[dataKey]?.value || condition[key]
        } else {
          acc[key] = replaceConditionValues(condition[key], data)
        }
        return acc
      },
      {} as Record<string, any>
    )
  }

  return condition
}

/**
 * Flattens valid conditions by extracting non-key fields.
 * @param validConditions - The valid conditions to flatten.
 * @returns The flattened conditions.
 */
function flattenValidConditions(validConditions: any[]): any[] {
  const flattenedConditions: any[] = []

  function extractNonKeyFields(condition: any) {
    const { condition: subConditions, op, ...rest } = condition

    const nonKeyFields = Object.keys(rest).reduce(
      (acc, key) => {
        if (!['value', 'key', 'op'].includes(key)) {
          acc[key] = rest[key]
        }
        return acc
      },
      {} as Record<string, any>
    )

    if (Object.keys(nonKeyFields).length > 0) {
      flattenedConditions.push(nonKeyFields)
    }

    if (['and', 'or'].includes(op) && Array.isArray(subConditions)) {
      subConditions.forEach(subCondition => {
        extractNonKeyFields(subCondition)
      })
    }
  }

  validConditions.forEach(condition => {
    extractNonKeyFields(condition)
  })

  return flattenedConditions
}

/**
 * Evaluates a condition and tracks valid conditions.
 * @param data - The data object to evaluate against.
 * @param condition - The condition to evaluate.
 * @returns The flattened valid conditions.
 */
export const evaluateConditionWithTracking = (
  data: any,
  condition: any
): any[] => {
  const validConditions: any[] = []

  function evaluateConditionWithValidList(
    data: any,
    condition: any,
    parent: any
  ): boolean {
    const { op, condition: subCondition = [] } = condition

    const result = conditionIsFullfilled(condition, data)
    if (result === true && !['and', 'or', 'not'].includes(op)) {
      validConditions.push({ ...(parent || {}), ...condition })
    }

    if (['and', 'or', 'not'].includes(op)) {
      subCondition.forEach((sub: Condition) =>
        evaluateConditionWithValidList(data, sub, condition)
      )
    }

    return result
  }

  const keyMapping = {
    conditionOperator: 'op',
    conditionValue: 'value',
    conditionVariable: 'key',
  }

  const replacedValues = replaceConditionValues(condition, data)
  const replacedKeys = replaceKeys(replacedValues, keyMapping)

  evaluateConditionWithValidList(data, replacedKeys, null)

  return flattenValidConditions(validConditions)
}

/**
 * Replaces wildcards in a formula with a prefix.
 * @param prefix - The prefix to use for replacement.
 * @param formula - The formula to process.
 * @returns The updated formula.
 */
export const replaceWildcardsWithPrefix = (
  prefix: string,
  formula: string = ''
): string => {
  const prefixParts = prefix.split('.')
  const prefixMap: Record<string, string> = {}

  prefixParts.forEach(part => {
    const match = part.match(/^(\w+)\[(\d+)\]$/)
    if (match) {
      const [, key, index] = match
      prefixMap[key] = index
    }
  })

  const updatedFormula = (formula || '').replace(
    /(\w+)\[(.*?)\]/g,
    (_, key, wildcard) => {
      return prefixMap[key]
        ? `${key}[${prefixMap[key]}]`
        : `${key}[${wildcard}]`
    }
  )

  return updatedFormula
}

/**
 * Adjusts data keys based on a prefix and values.
 * @param prefix - The prefix to use for adjustment.
 * @param values - The values object.
 * @param data - The data object to adjust.
 * @returns The adjusted data.
 */
export function adjustDataKeys(prefix: string, values: any, data: any): any {
  function findPath(
    obj: any,
    query: string,
    currentPath: string = ''
  ): string | null {
    let result: string | null = null

    for (const key in obj) {
      const value = obj[key]
      const path = Array.isArray(obj)
        ? `${currentPath}[*]`
        : currentPath
          ? `${currentPath}.${key}`
          : key

      if (key === query) {
        return path
      }

      if (typeof value === 'object' && value !== null) {
        result = findPath(value, query, path)
        if (result) {
          break
        }
      }
    }

    return result
  }

  function updateKey(key: string): string {
    const path = prefix ? `${prefix}.${key}` : key
    if (get(values, path) !== undefined) {
      return path
    }
    const fullPath = findPath(values, key) ?? ''
    return prefix ? replaceWildcardsWithPrefix(prefix, fullPath) : fullPath
  }

  function processCondition(condition: any): any {
    if (Array.isArray(condition)) {
      return condition.map(processCondition)
    }

    if (condition && typeof condition === 'object') {
      if (condition.condition) {
        return {
          ...condition,
          condition: processCondition(condition.condition),
        }
      }

      if (condition.key) {
        return {
          ...condition,
          key: updateKey(condition.key),
        }
      }
    }
    return condition
  }

  if (!data.condition && data.key) {
    return {
      ...data,
      key: updateKey(data.key),
    }
  }
  return {
    ...data,
    condition: processCondition(data.condition),
  }
}
