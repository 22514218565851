import { memo, useMemo } from 'react'
import jmespath from 'jmespath'
import { useForm } from 'react-final-form'
import { isNil } from 'lodash'

import { getModel } from 'data-model'

import { handleOptions } from 'data-model/src/shared/options'

import { setValue } from './helpers'
import { TypeFieldCheckBox } from '../TypeField/TypeFieldCheckBox'
import { TypeFieldEntity } from '../TypeField/TypeFieldEntity'
import { TypeFieldIdCheck } from '../TypeField/TypeFieldIdCheck'
import { TypeFieldInfo } from '../TypeField/TypeFieldInfo'
import { TypeFieldSelector } from '../TypeField/TypeFieldSelector'
import { TypeFieldSiret } from '../TypeField/TypeFieldSiret'
import { TypeFieldUpload } from '../TypeField/TypeFieldUpload'
import { TypeField } from '../TypeField/TypeField'
import { getView } from '../../helpers/getView'

// Define the type for the component props
interface FormFieldContentProps {
  initialValue?: string
  name: string
  user?: any
  type: string
  language?: string
  options?: any
  frame?: any
  hidden?: boolean
  filename?: string
  [key: string]: any // Allow additional props
}

export const FormFieldContent = memo(
  ({
    initialValue: initialValueProps,
    name,
    user,
    type,
    language,
    ...props
  }: FormFieldContentProps) => {
    const initialValue = isNil(initialValueProps) ? '' : initialValueProps
    language = (language && language.toLowerCase()) || 'en'
    const frame = props.frame

    const form = useForm()
    const { getState } = useMemo(() => form, [form])

    const defaultValue = useMemo(() => {
      return typeof initialValue === 'string' && initialValue.startsWith('@')
        ? jmespath.search(getState().values, initialValue.slice(1))
        : initialValue
    }, [getState, initialValue])

    const model = useMemo(() => getModel({ type, ...props }), [props, type])

    const { View, viewOptions } = useMemo(() => getView(type), [type])

    const params = useMemo(() => {
      const propsWithViewOptions: any = { ...(viewOptions || {}), ...props }

      return {
        definition: { ...model, View },
        filename_en: propsWithViewOptions.filename || undefined,
        locale: language,
        user,
        ...Object.keys(propsWithViewOptions).reduce(
          (acc, propName) =>
            propName.endsWith(`_${language}`)
              ? {
                  ...acc,
                  [propName.slice(0, -1 - language.length)]:
                    propsWithViewOptions[propName],
                }
              : acc,
          propsWithViewOptions
        ),
        defaultValue,
        id: `label_${name}`,
        initialOptions: props.options,
        name,
        options: handleOptions(props.options, model, language),
        type,
      }
    }, [
      View,
      defaultValue,
      language,
      model,
      name,
      props,
      type,
      user,
      viewOptions,
    ])

    if (params.hidden === true || type === 'uuid') {
      setValue(params, defaultValue, form)
      return null
    }

    if (type === 'info') {
      return <TypeFieldInfo {...params} />
    } else if (type === 'checkbox') {
      return <TypeFieldCheckBox {...params} />
    }

    if (!View) {
      throw Error(`missing view for type ${type}, name: ${props.name}`)
    }

    if (!Boolean(model)) {
      return <View {...viewOptions} {...params} />
    }

    switch (model.dataOptions?.asyncData) {
      case 'registrar':
      case 'dossiers':
      case 'countries':
      case 'cities':
      case 'nationality':
      case 'legalFormFr':
      case 'companyMemberRoles':
        return <TypeFieldSelector {...params} />
      case 'bodacc':
      case 'insee':
      case 'siret':
        return <TypeFieldSiret {...{ frame, ...params }} />
      case 'upload':
        return <TypeFieldUpload {...params} />
      case 'idCheck':
        return <TypeFieldIdCheck {...params} />
      case 'entity':
        return <TypeFieldEntity {...{ frame, ...params }} />
      default:
        return <TypeField {...params} />
    }
  }
)
