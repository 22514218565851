interface HandleFieldWithoutPrefixParams {
  prefix?: string
  filename?: string
  [key: string]: any // Allow any additional properties
}

export const handleFieldWithoutPrefix = (
  params: HandleFieldWithoutPrefixParams
): Record<string, string> => {
  const filename: Record<string, string> = {}
  const regex = new RegExp('^filename_')

  // Example (args => result): filename_en => EN
  const handleKeyFileName = (key: string): string =>
    key.split('_')[1].toUpperCase()

  if (params.prefix) {
    const prefixWithDot = params.prefix.concat('.')
    const prefixWithOutIndex = params.prefix
      .slice(0, params.prefix.lastIndexOf('['))
      .concat('.')

    Object.keys(params)
      .filter(key => regex.test(key))
      .forEach(key => {
        const keyHandled = handleKeyFileName(key)
        if (params[key]) {
          if (params[key].includes(prefixWithDot)) {
            filename[keyHandled] = params[key]
          } else {
            filename[keyHandled] = params[key].replaceAll(
              prefixWithOutIndex,
              prefixWithDot
            )
          }
        }
      })
  } else {
    Object.keys(params)
      .filter(key => regex.test(key))
      .forEach(key => (filename[handleKeyFileName(key)] = params[key]))
  }

  // If no precision (_LANG), then EN = filename field
  return Object.keys(filename).length === 0
    ? { EN: params.filename || '' }
    : filename
}
