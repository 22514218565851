import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import fr from '../../../node_modules/@syncfusion/ej2-locale/src/fr.json'

import { DocumentEditorContainerComponent } from '@syncfusion/ej2-react-documenteditor'
import { Loader } from '../Loader/Loader'

import { L10n } from '@syncfusion/ej2-base'
import { convertEJSToTags } from '../../helpers'
L10n.load(fr)

// Dynamically importing styles
;[
  'base',
  'react-buttons',
  'react-inputs',
  'react-popups',
  'react-lists',
  'react-navigations',
  'react-splitbuttons',
  'react-dropdowns',
  'react-documenteditor',
].map(name => import('@syncfusion/ej2-' + name + '/styles/material3.css'))

interface DocumentEditorComponentProps {
  height?: string | number
  width?: string | number
  content?: string
  convertTag?: boolean
  language?: string
  [key: string]: any
}

export const DocumentEditorComponent = forwardRef<
  DocumentEditorContainerComponent | null,
  DocumentEditorComponentProps
>(
  (
    {
      height = '100%',
      width = '100%',
      content,
      language = 'fr',
      convertTag = true,
      ...properties
    },
    ref
  ) => {
    const cardsRef = useRef<HTMLDivElement | null>(null)
    const [loading, setLoading] = useState<boolean>(true)

    const initContent = useCallback(async () => {
      if (ref && 'current' in ref && ref.current?.documentEditor && content) {
        const { sfdt } = await convertEJSToTags(content)
        ref.current.documentEditor.open(sfdt)
      }
    }, [content, ref])

    useEffect(() => {
      initContent()
    }, [content, ref])

    useEffect(() => {
      const observer = new ResizeObserver(() => {
        if (cardsRef.current && ref && 'current' in ref && ref.current) {
          ref.current.resize()
        }
      })

      if (cardsRef.current) {
        observer.observe(cardsRef.current)
      }

      return () => observer.disconnect()
    }, [ref])

    const onCreated = useCallback(() => {
      setLoading(false)
    }, [])

    if (!content) {
      return <Loader isRelative />
    }

    return (
      <div
        ref={cardsRef}
        style={{
          opacity: loading ? 0 : 1,
          position: 'relative',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        {loading && <Loader />}
        <DocumentEditorContainerComponent
          {...{
            height,
            width,
            ref,
            ...properties,
          }}
          created={onCreated}
          locale={language}
        />
      </div>
    )
  }
)
