import gql from 'graphql-tag'

export const getEntityQuery = gql`
  query GetEntity($id: ID!) {
    getEntity(id: $id) {
      id
      data
      name
      userId
      dataResolved
      type {
        id
        entityNamePath
        creationForm
        title
      }
    }
  }
`

export const getProcessInstancesQuery = gql`
  query GetProcessInstances(
    $offset: Int
    $sortBy: ProcessInstanceSortBy
    $sortDirection: SortDirection
    $search: String
    $dossiersListType: String
    $from: String
    $to: String
    $limit: Int
  ) {
    getProcessInstances(
      offset: $offset
      sortBy: $sortBy
      sortDirection: $sortDirection
      search: $search
      dossiersListType: $dossiersListType
      from: $from
      to: $to
      limit: $limit
    ) {
      count
      edges {
        node {
          id
          label
        }
      }
      totalCount
    }
  }
`

export const getProcessInstanceQuery = gql`
  query ProcessInstancePageQuery($id: ID!) {
    getProcessInstance(id: $id) {
      data
      bcc
      dataResolved
      documents {
        id
        user {
          id
          firstname
          lastname
          email
          id
          type
          facebookId
        }
        languages
        filename
        filetype
        templateId
        url
        createdAt
        updatedAt
        flags
        tags
        isLatest
        isDraft
        hasHtml
        documentVersion
        currentVersion
        versions {
          version
          createdAt
          isEdited
        }
        status
        size
      }
      id
      label
      name
      status
      user {
        id
      }
      workflow {
        config
        configResolved
        id
        name
        dependsOnPreviousSteps
        description
        status
        type
        toRevalidate
        restrictedAccess
        access {
          read
          write
        }
      }
      timeSpent
      userTimer {
        id
        createdAt
        note
      }
    }
  }
`

export const getInseeMunicipalitiesByPostalCodeQuery = gql`
  query getInseeMunicipalitiesByPostalCode($postalCode: String) {
    getInseeMunicipalitiesByPostalCode(postalCode: $postalCode) {
      id
      inseeCode
      postalCode
      name
      label
    }
  }
`

export const getCountriesEuQuery = gql`
  query getCountriesEu {
    getCountriesEu {
      id
      isoCode2
      label
      label_en
      nationality
    }
  }
`

export const getCountriesQuery = gql`
  query getCountries {
    getCountries {
      id
      isoCode2
      label
      label_en
      nationality
    }
  }
`

export const getRegistrarQuery = gql`
  query GetRegistrar($codeCommune: String) {
    getRegistrar(codeCommune: $codeCommune) {
      id
      registrarCode
      name
    }
    getRegistrars {
      id
      registrarCode
      name
    }
  }
`

export const getCompanyMemberRolesQuery = gql`
  query getCompanyMemberRoles($companyType: Int) {
    getCompanyMemberRoles(companyType: $companyType)
  }
`

export const getInseeLegalFormsFrQuery = gql`
  query getInseeLegalFormsFr {
    getInseeLegalFormsFr {
      id
      label
      category
    }
  }
`

export const getEntityCategoriesQuery = gql`
  query GetEntityCategories {
    getEntityCategories {
      id
      type {
        id
        typeName
        typeNamePlural
        title
        icon
        position
        creationForm
      }
      categoryName
      entityNamePath
      title
      icon
      entityCount
      createdAt
      updatedAt
    }
  }
`

export const getDocumentByIdQuery = gql`
  query getDocumentById($documentId: ID!) {
    getDocumentById(documentId: $documentId) {
      id
      url
      filename
    }
  }
`

export const getEntitiesByCategoryNameQuery = gql`
  query GetEntitiesByCategoryName($categoryName: [String!]) {
    getEntitiesByCategoryName(categoryName: $categoryName, addPublic: true) {
      id
      name
      data
      category {
        id
        categoryName
      }
      type {
        id
        title
        typeName
        icon
      }
    }
  }
`

export const getCompanyInfoBySiretQuery = gql`
  query getCompanyInfoBySiret($siret: String!) {
    getCompanyInfoBySiret(siret: $siret)
  }
`

export const getCompanyBodaccEntriesQuery = gql`
  query getCompanyBodaccEntries($siret: String!) {
    getCompanyBodaccEntries(siret: $siret)
  }
`
