import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-final-form'
import { useMutation } from '@apollo/client'
import classNames from 'classnames'
import pick from 'lodash/pick'

import { getFieldValuesMutation } from './TypeField/graphql/mutations'
import { useUpdateField } from '../hooks/useUpdateField'

import { Row, Popover } from 'antd'

import { Label, Icon } from 'data-view'
import { isNil } from 'lodash'
import { useReactiveCalculation } from '../hooks/useReactiveCalculation'
import { extendedLocaleToLang } from 'data-model/src/shared/formatters'
import { usePermissions } from '../hooks/usePermissions'
import { FEATURES } from '../constants'
import {
  AIIcon,
  ButtonType,
  Happy,
  InnerButton,
  Loader,
  Modal,
  RightArrowIcon,
  Tag,
  Tooltip,
} from '../components'
import { isNameInLabel } from '../helpers'

// ✅ Define Props Type
interface FieldViewProps {
  processInstance?: any
  meta: {
    touched?: boolean
    error?: string
  }
  input: {
    value: any
    onChange: (value: any) => void
    name?: string
  }
  user?: any
  definition: {
    View: React.ComponentType<any>
    fake: (options?: any) => any
    dataOptions?: Record<string, any>
  }
  options?: any[]
  initialOptions?: any
  preview?: boolean
  label?: string
  description?: string
  required?: boolean
  display?: boolean
  viewOnly?: boolean
  calculation?: any
  prefix?: string
  userId?: string
  stepId?: string
  asyncData?: any
  defaultValue?: any
  type?: string
  handleImportClick?: any
  errorMsg?: string
  getBodaccEntries?: any
  loading?: boolean
  frame?: any
}

export const FieldView: React.FC<FieldViewProps> = ({
  meta,
  input,
  user,
  definition: { View, fake, dataOptions },
  options,
  label,
  description,
  required,
  viewOnly,
  calculation,
  ...params
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const lang = useMemo(() => extendedLocaleToLang(language), [language])

  // Extract relevant props
  const { prefix, asyncData, processInstance, ...props } = params

  const handleClick = useCallback(() => {
    input.onChange(
      fake(
        Array.isArray(options) && options.length > 0
          ? options.map(option => option.value || option)
          : undefined
      )
    )
  }, [fake, input, options])

  const cleanDataOptions = useMemo(
    () => pick(dataOptions, ['min']),
    [dataOptions]
  )

  const hasError = useMemo(() => meta.touched && meta.error, [meta])

  const errorText = useMemo(() => {
    switch (meta.error) {
      case 'requiredField':
        return t('common.error.required.generic')
      case 'incorrectEmail':
        return t('common.error.input.email.invalid')
      default:
        return null
    }
  }, [t, meta])

  useEffect(() => {
    if (isNil(input.value) && props.defaultValue && !calculation) {
      setTimeout(() => {
        input.onChange(props.defaultValue)
      }, 30)
    }
  }, [input, props.defaultValue, calculation])

  const [scanning, setScanning] = useState<boolean>(false)
  const [displayModal, setDisplayModal] = useState<boolean>(false)

  const form = useForm()
  const [getFieldValues] = useMutation(getFieldValuesMutation)
  const updateField = useUpdateField()

  if (calculation) {
    useReactiveCalculation(input, calculation, prefix)
  }

  const onClick = async () => {
    setScanning(true)

    try {
      const { data } = await getFieldValues({
        variables: {
          fields: form.getState().values,
          documents: [input.value],
          names: form.getRegisteredFields(),
        },
      })

      if (data?.getFieldValues) {
        Object.entries(data.getFieldValues).forEach(([field, value]) => {
          if (typeof value === 'string' && value) {
            updateField(form, props.frame, processInstance, field, value)
          }
        })
      }
    } finally {
      setScanning(false)
      setDisplayModal(true)
    }
  }

  const processLabel = useMemo(
    () => processInstance?.product?.process?.label,
    [processInstance]
  )
  const isUsedInLabel = useMemo(
    () => processLabel && isNameInLabel(input.name || '', processLabel),
    [input, processLabel]
  )

  const admin = useMemo(
    () => user && usePermissions(user, FEATURES.superadmin),
    [user]
  )

  if (viewOnly) {
    return (
      <View
        className="form-runner-field-view"
        {...cleanDataOptions}
        {...props}
        {...input}
        {...{ lang, t, meta, options }}
      />
    )
  }

  return (
    <div
      className={classNames(
        'form-runner-field',
        props.type === 'checkbox' && 'form-runner-field--oneline'
      )}
    >
      <Row justify="space-between">
        {/* @ts-ignore */}
        <Label
          {...{ label, required }}
          validateStatus={hasError ? 'error' : 'success'}
        >
          {isUsedInLabel && (
            <Tag size="small" type="draft">
              {t('workflowv2Form.form.used')}
            </Tag>
          )}
          {Boolean(description) && (
            <Popover
              align={{ offset: [0, 10] }}
              content={description}
              arrowPointAtCenter
              overlayClassName="form-runner-field-moreinfo-overlay"
            >
              <InnerButton
                type={ButtonType.link}
                className="form-runner-field-moreinfo"
              >
                <span>
                  {t('common.text.moreinfo')} <RightArrowIcon />
                </span>
              </InnerButton>
            </Popover>
          )}
          {hasError && errorText && (
            <Tag type="error" className="form-field-errortag">
              &nbsp;
              <Happy />
              &nbsp;{errorText}&nbsp;
            </Tag>
          )}
          {admin && dataOptions?.asyncData === 'upload' && (
            <>
              <Tooltip
                placement="left"
                title={t('formRunner.fieldView.fill.tooltip')}
              >
                <InnerButton
                  disabled={!input.value || scanning}
                  type={ButtonType.text}
                  className="chat-box-button"
                  icon={scanning ? <Loader /> : <AIIcon />}
                  onClick={onClick}
                />
              </Tooltip>
              {displayModal && (
                <Modal
                  isShown={displayModal}
                  modalContent={t(
                    'formRunner.fieldView.fill.success.description'
                  )}
                  hide={() => setDisplayModal(false)}
                  headerText={t('formRunner.fieldView.fill.success.title')}
                />
              )}
            </>
          )}
        </Label>
        {admin && (
          <Tooltip placement="left" title={t('formRunner.fieldView.fake')}>
            <InnerButton
              disabled={!Boolean(fake)}
              type={ButtonType.text}
              icon={<Icon width="24" name="dice" />}
              onClick={handleClick}
            />
          </Tooltip>
        )}
      </Row>
      <Row key="form-field" justify="space-between">
        <View
          className="form-runner-field-view"
          {...{
            language,
            lang,
            t,
            ...cleanDataOptions,
            ...props,
            ...input,
            meta,
            options,
          }}
          status={hasError ? 'error' : undefined}
        />
      </Row>
    </div>
  )
}
