import { FormFieldContent } from './FormFieldContent'
import { FormFieldMultiple } from './FormFieldMultiple'

interface FormFieldProps {
  type: string // Optional because it might not always be provided
  fields: any[]
  name: string
  [key: string]: any // Allow additional props
}

export const FormField: React.FC<FormFieldProps> = props =>
  props.type === 'multiple' ? (
    <FormFieldMultiple {...props} />
  ) : (
    <FormFieldContent {...props} />
  )
