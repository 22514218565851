import gql from 'graphql-tag'
import { applicationFragment } from '../fragments'

export const getCurrentApplicationQuery = gql`
  ${applicationFragment}
  query GetCurrentApplication {
    getCurrentApplication {
      ...applicationFragment
    }
  }
`
