export const getUrlConfig = () => {
  const LOCATION = window.location.host
    .split('.')
    .slice(-2)
    .join('.')
    .split(':')[0]
  const SLUG = localStorage.getItem('slug') || 'demo'

  return {
    LOCATION,
    SLUG,
    BASE_URL: `${window.location.protocol}//api.${LOCATION}`,
    LOCAL_URL: `${window.location.protocol}//${SLUG}.${LOCATION}`,
    APP_URL: `${window.location.protocol}//api.${LOCATION}/${SLUG}`,
    GRAPHQL_ENDPOINT: `${window.location.protocol}//api.${LOCATION}/${SLUG}/graphql`,
  }
}
