import React from 'react'
import { useCallback, useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { v4 as uuid } from 'uuid'
import {
  uploadDocumentMutation,
  replaceDocumentMutation,
} from '../../graphql/mutations/document'

import { FieldView } from '../FieldView'
import { useBindingField } from '../../hooks/useBindingField'

import { getDocumentByIdQuery } from './graphql/queries'
import { handleFieldWithoutPrefix } from './helpers'
import { getPreviewLink } from '../../helpers/document'
import { getOrcreateFiletypeByTitleAndApplicationIdMutation } from '../../graphql/mutations/filetype'

interface TypeFieldIdCheckProps {
  name: string
  fileLanguage?: string
  stepId?: string
  signer?: string
  preview?: boolean
  processInstance?: { id: string }
  tags?: string[]
  signatureType?: string
  filetypeId?: string
  signaturePosition?: string
  application: any
  definition: any
}

export const TypeFieldIdCheck: React.FC<TypeFieldIdCheckProps> = params => {
  const [uploadDocument] = useMutation(uploadDocumentMutation)
  const [replaceDocument] = useMutation(replaceDocumentMutation)
  const [submitting, setSubmitting] = useState<string | false>(false)

  const { input, meta } = useBindingField(params)
  const [documentFrontId, setDocumentFrontId] = useState<string | null>(
    input?.value?.front || null
  )
  const [documentBackId, setDocumentBackId] = useState<string | null>(
    input?.value?.back || null
  )
  const [documentType, setDocumentType] = useState<string | null>(
    input?.value?.type || null
  )
  const [getOrcreateFiletypeByTitleAndApplicationId] = useMutation(
    getOrcreateFiletypeByTitleAndApplicationIdMutation
  )
  const filename = handleFieldWithoutPrefix(params)

  const {
    application,
    name,
    fileLanguage,
    stepId,
    signer,
    preview,
    processInstance,
    tags,
    signatureType,
    signaturePosition,
  } = params

  const processInstanceId = processInstance?.id

  const metadata = useMemo(
    () => ({
      fieldName: name,
      fileLanguage,
      filename,
      processInstanceId,
      signaturePosition,
      signatureType,
      signer,
      processInstanceStepId: stepId,
    }),
    [
      name,
      fileLanguage,
      filename,
      processInstanceId,
      signaturePosition,
      signatureType,
      signer,
      stepId,
    ]
  )

  const { data: { getDocumentById: documentFront } = {} } = useQuery(
    getDocumentByIdQuery,
    {
      fetchPolicy: 'cache-and-network',
      skip: !documentFrontId,
      variables: {
        documentId: documentFrontId,
      },
    }
  )

  const { data: { getDocumentById: documentBack } = {} } = useQuery(
    getDocumentByIdQuery,
    {
      fetchPolicy: 'cache-and-network',
      skip: !documentBackId,
      variables: {
        documentId: documentBackId,
      },
    }
  )

  const document = useMemo(
    () => ({ back: documentBack, front: documentFront }),
    [documentFront, documentBack]
  )

  const customRequest = useCallback(
    (type: string, side: 'front' | 'back') =>
      async ({
        file,
        onSuccess,
      }: {
        file: File
        onSuccess: (id: string) => void
      }) => {
        let filetypeId
        const { data } = await getOrcreateFiletypeByTitleAndApplicationId({
          variables: {
            title:
              type === 'passport'
                ? { EN: 'Passport', FR: 'Passeport' }
                : { EN: 'Id card', FR: "Carte d'identité" },
            applicationId: application.id,
          },
        })
        if (data.getOrcreateFiletypeByTitleAndApplicationId) {
          filetypeId = data.getOrcreateFiletypeByTitleAndApplicationId.id
        }
        const wrap = {
          back: {
            get: documentBackId,
            set: setDocumentBackId,
          },
          front: {
            get: documentFrontId,
            set: setDocumentFrontId,
          },
        }
        if (!wrap[side]) {
          return
        }
        try {
          setSubmitting(side)
          if (wrap[side].get && document[side] && documentType === type) {
            const {
              data: {
                replaceDocument: { id },
              },
            } = await (preview
              ? Promise.resolve({ data: { replaceDocument: { id: uuid() } } })
              : replaceDocument({
                  variables: {
                    documentSource: { processInstanceId },
                    file,
                    id: wrap[side].get,
                  },
                }))
            onSuccess(id)
            wrap[side].set(id)
            input.onChange({
              ...input.value,
              [side]: id,
            })
          } else {
            const {
              data: {
                uploadDocuments: [{ id }],
              },
            } = await (preview
              ? Promise.resolve({ data: { uploadDocuments: [{ id: uuid() }] } })
              : uploadDocument({
                  variables: {
                    documentSource: { processInstanceId },
                    file,
                    filetypeId,
                    flags: ['form'],
                    metadata: { ...metadata, side },
                    tags,
                  },
                }))
            onSuccess(id)
            wrap[side].set(id)
            input.onChange({
              ...input.value,
              [side]: id,
            })
          }
          setDocumentType(type)
        } catch (error) {
          console.error('Error uploading/replacing document:', error)
        }

        setSubmitting(false)
      },
    [
      metadata,
      preview,
      documentType,
      processInstanceId,
      tags,
      input,
      document,
      uploadDocument,
      replaceDocument,
      documentBackId,
      documentFrontId,
    ]
  )

  return (
    <FieldView
      {...{
        ...params,
        meta,
        input,
        submitting,
        customRequest,
        getPreviewLink,
        document,
      }}
    />
  )
}
