export const PROCESS_STEP = {
  DONE: 'DONE',
  ERROR: 'ERROR',
  NOT_STARTED: 'not-started',
  PENDING: 'PENDING',
}

export const JOB_TYPES = {
  authenticate: 'authenticate',
  docPreview: 'docPreview',
  form: 'form',
  generateDocuments: 'generateDocuments',
  inviteUsers: 'inviteUsers',
  notifications: 'notifications',
  openDocument: 'openDocument',
  condition: 'condition',
  payment: 'payment',
  publishLegalNotice: 'publishLegalNotice',
  sendDocuments: 'sendDocuments',
  sendLetter: 'sendLetter',
  sendMailWithPreview: 'sendMailWithPreview',
  shareId: 'shareId',
  signDocuments: 'signDocuments',
  syncEntity: 'syncEntity',
}
