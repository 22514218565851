import gql from 'graphql-tag'

export const getCurrentUserQuery = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      createdAt
      type
      firstname
      lastname
      email
      welcomedAt
      facebookId
      incomingMail
      phone
      role {
        id
        title
        features
        isAdmin
      }
      groups {
        id
        color
        name
        userCount
      }
    }
  }
`
