import gql from 'graphql-tag'

export const uploadDocumentMutation = gql`
  mutation uploadDocument(
    $file: Upload!
    $documentSource: DocumentSourceInput!
    $metadata: JSONObject
    $flags: [Fileflag]
    $filetypeId: ID
    $tags: JSON
  ) {
    uploadDocuments(
      files: [$file]
      documentSource: $documentSource
      metadata: [$metadata]
      flags: [$flags]
      filetypeId: $filetypeId
      tags: [$tags]
    ) {
      filename
      id
      mimetype
      processInstanceId
      url
      metadata
      flags
      filetype {
        id
        title
      }
      tags
    }
  }
`

export const replaceDocumentMutation = gql`
  mutation ReplaceDocument(
    $file: Upload!
    $id: ID!
    $documentSource: DocumentSourceInput
  ) {
    replaceDocument(file: $file, id: $id, documentSource: $documentSource) {
      id
      url
      filename
    }
  }
`

export const getFieldValuesMutation = gql`
  mutation ($documents: [String]!, $fields: JSONObject!, $names: [String]!) {
    getFieldValues(documents: $documents, fields: $fields, names: $names)
  }
`
