import { useEffect } from 'react'

interface TypeFieldInfoProps {
  label?: string
  value?: string
  locale?: string
  useFAQ?: any
}

export const TypeFieldInfo: React.FC<TypeFieldInfoProps> = ({
  label,
  value,
  locale,
  useFAQ,
}) => {
  const faqContext = useFAQ?.()
  const dispatch = faqContext?.dispatch

  useEffect(() => {
    if (dispatch) {
      dispatch({ content: { label, value }, type: 'addContent' })
    }
  }, [dispatch, label, value, locale])

  return null
}
