import { useBindingField } from '../../hooks/useBindingField'
import { FieldView } from '../FieldView'

interface TypeFieldProps {
  name: string
  useParentField?: boolean
  parentField?: string
  definition: any
  [key: string]: any // Allow additional props
}

export const TypeField: React.FC<TypeFieldProps> = ({ name, ...params }) => {
  const { input, meta } = useBindingField({
    name:
      params.useParentField && params.parentField ? params.parentField : name,
    ...params,
  })

  return <FieldView {...params} input={input} meta={meta} />
}
