import { CONTENT_TYPE_SYNCFUSION_DOCUMENT_TEXT } from '../../constants'
import { supportedTypes } from './images'

const SyncfusionDocumentText = 'sfdt'

const typeNames = [
  [SyncfusionDocumentText, 'doc', 'docx'],
  ['html', 'plain', 'markdown'],
  ['image', 'svg+xml'],
]

export const getType = (filename, type, url) => {
  if (type === CONTENT_TYPE_SYNCFUSION_DOCUMENT_TEXT) {
    return [SyncfusionDocumentText, SyncfusionDocumentText]
  }
  if (type) {
    const splitted = type.split('/')

    type = splitted[Number(1 < splitted.length && splitted[0] !== 'image')]
  } else {
    const extension = (filename || url || '').split('.').pop().toLowerCase()

    type = supportedTypes.includes(extension) ? 'image' : extension
  }

  return [type, typeNames.find(names => names.includes(type))?.[0] ?? type]
}
