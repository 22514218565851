// Define types for the function parameters
type ClickMethod = (link: HTMLAnchorElement) => void
type AfterCallback = (link: HTMLAnchorElement) => void

export const click = (
  href: string,
  method?: ClickMethod, // optional method
  after?: AfterCallback // optional after callback
): void => {
  const link = document.createElement('a')

  link.href = href

  if (method) {
    method(link)

    document.body.appendChild(link)
  }

  link.click()

  if (after) after(link)

  if (method) document.body.removeChild(link)
}

export const object = (content: BlobPart, type: string = 'pdf'): void => {
  click(
    URL.createObjectURL(new Blob([content])), // Convert content to Blob
    (link: HTMLAnchorElement) => {
      link.download = 'data.' + type
    },
    (link: HTMLAnchorElement) => URL.revokeObjectURL(link.href)
  )
}

export const core = (content: string, type: string = 'csv'): void => {
  object(new Blob([content]), type) // Convert string content into Blob
}

export const download = (content: unknown, ...parameters: any[]): void => {
  core(JSON.stringify(content, ...parameters), 'json')
}
