import {
  useRef,
  Suspense,
  useMemo,
  useCallback,
  useState,
  useEffect,
  lazy,
} from 'react'
import { useQuery } from '@apollo/client'

import isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import { FieldArray } from 'react-final-form-arrays'
import { Row, Pagination } from 'antd'

const FormFieldCheckConditional = lazy(() =>
  import('../FormField/FormFieldCheckConditional').then(module => ({
    default: module.FormFieldCheckConditional,
  }))
)

import { nth } from 'data-model/src/shared/formatters'
import scrollIntoView from 'scroll-into-view-if-needed'

import { get } from 'lodash'
import { useLocation } from '../../hooks/useLocation'
import {
  ButtonType,
  Collapse,
  DeleteIcon,
  InnerButton,
  Loader,
  PlusIcon,
  SearchBar,
} from '../../components'
import { ITEMS_PER_PAGE_TINY } from '../../constants'
import { isEmptyOrWhitespace, resolveJmespath, sleep } from '../../helpers'
import { getCompanyMemberRolesQuery } from '../TypeField/graphql/queries'

const parseHash = (hash: string) => {
  // Regex to decompose the hash
  const regex = /^([a-zA-Z0-9_]+)\[(\d+)\]\.([a-zA-Z0-9_]+)$/

  // Test if the hash matches the pattern
  const match = hash.match(regex)

  if (match) {
    const name = match[1]
    const index = parseInt(match[2], 10)
    const target = match[3]

    return { name, index, target }
  }

  // If the hash doesn't match the expected pattern
  return null
}

type FormFieldMultipleContentProps = {
  fields: any[]
  name: string
  preview?: boolean
  processInstance?: any
  stepId?: string
  frame?: any
  user?: any
  displayName?: string
}

export const FormFieldMultipleContent: React.FC<
  FormFieldMultipleContentProps
> = ({
  fields = [],
  name,
  preview,
  processInstance,
  stepId,
  frame,
  user,
  displayName,
}) => {
  const lastElement = useRef()
  const location = useLocation()

  const { t, i18n: { language = 'en' } = {} } = useTranslation()
  const [startIndex, setStartIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(9)
  const [page, setPage] = useState(1)
  const [stringFilter, setStringFilter] = useState('')

  useEffect(() => {
    setStartIndex(page * ITEMS_PER_PAGE_TINY - ITEMS_PER_PAGE_TINY)
    setEndIndex(page * ITEMS_PER_PAGE_TINY)
  }, [page])

  const value = useMemo(
    () =>
      (processInstance?.dataResolved &&
        get(processInstance.dataResolved, name)) ||
      {},
    [name, processInstance?.dataResolved]
  )
  const [selectedCollapse, setSelectedCollapse] = useState<number>(0)

  const { data: { getCompanyMemberRoles: roles = [] } = {}, loading } =
    useQuery(getCompanyMemberRolesQuery, {
      skip: !fields.filter(({ type }) => type === 'companyMemberRoles').length,
    })

  const handleDisplayNameResolution = useCallback(
    (memberValues: any, index: number) => {
      const extra: Record<string, any> = {}

      if (memberValues?.role?.type === 'companyMemberRoles') {
        const role = roles.find(
          (role: any) => role.value === memberValues?.role?.value
        )
        if (role) {
          extra['role'] = {
            ...memberValues.role,
            value: role[language],
          }
        }
      }

      const name = resolveJmespath(
        {
          ...memberValues,
          ...extra,
          __user: user,
          __index: nth(index + 1, { locale: language }),
        },
        displayName ?? '',
        language
      )
      return !isEmptyOrWhitespace(name) && name
    },
    [displayName, language, t, user, roles]
  )

  const scroll = () => {
    const node = lastElement.current
    if (node) {
      scrollIntoView(node, {
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
        scrollMode: 'if-needed',
      })
    }
  }

  const handleCollapseChange = (val: any) => {
    setSelectedCollapse(val[0])
  }

  useEffect(() => {
    // Check if there is any hash in the URL
    if (location.hash) {
      const cleanHash = location.hash.replace(/^#label_/, '')
      const result = parseHash(cleanHash)

      if (result) {
        const { index } = result
        setSelectedCollapse(index)
      }
    }
  }, [location.hash]) // Runs whenever location.hash changes

  if (loading) {
    return <Loader />
  }

  return (
    // Initial value is set to have at least 1 element in form
    <FieldArray
      {...(Array.isArray(value) && value.length > 0 && !isEmpty(value[0])
        ? { initialValue: value }
        : { initialValue: [1] })}
      name={name}
    >
      {({ fields: formFields }) => {
        const items = useMemo(() => {
          let _formFields = formFields.map((fieldName, index) => ({
            key: index,
            title: (
              <Row
                justify="space-between"
                align="middle"
                style={{ width: '100%' }}
              >
                <div>
                  {handleDisplayNameResolution(formFields.value[index], index)}
                </div>
                <InnerButton
                  disabled={(formFields.length ?? 0) <= 1}
                  icon={<DeleteIcon />}
                  onClick={() => formFields.remove(index)}
                  type={ButtonType.text}
                />
              </Row>
            ),
            content: (
              // @ts-ignore
              <div
                key={index}
                {...(formFields.length === index + 1
                  ? { ref: lastElement }
                  : {})}
              >
                <Row gutter={[0, 24]} style={{ width: '100%' }}>
                  {fields.map((field, fieldIndex) => (
                    <Suspense fallback={<Loader />} key={fieldIndex}>
                      <FormFieldCheckConditional
                        {...field}
                        initialValue={
                          (Array.isArray(value) &&
                            value[index] &&
                            (value[index][field.name]?.value ||
                              value[index][field.name])) ||
                          field.value ||
                          field.initialValue ||
                          undefined
                        }
                        {...{
                          user,
                          preview,
                          processInstance,
                          stepId,
                          language,
                          frame,
                        }}
                        prefix={fieldName}
                        name={`${fieldName}.${field.name}`}
                      />
                    </Suspense>
                  ))}
                </Row>
              </div>
            ),
          }))

          if (stringFilter !== '') {
            return _formFields
              .filter((_, index) => {
                return handleDisplayNameResolution(
                  formFields.value[index],
                  index
                ).includes(stringFilter)
              })
              .slice(startIndex, endIndex)
          } else {
            return _formFields.slice(startIndex, endIndex)
          }
        }, [formFields, stringFilter])

        const handleAddField = async () => {
          formFields.push({})
          setSelectedCollapse(formFields.length ?? 0)
          setStringFilter('')
          await sleep(3e2)
          scroll()
        }

        return (
          <>
            {(formFields.length ?? 0) > ITEMS_PER_PAGE_TINY && (
              <>
                <Row
                  className="collapse-header-action"
                  justify={'end'}
                  align={'middle'}
                >
                  <SearchBar {...{ setStringFilter }} />
                  <Pagination
                    className="pagination"
                    defaultCurrent={page}
                    defaultPageSize={ITEMS_PER_PAGE_TINY}
                    hideOnSinglePage
                    onChange={_page => setPage(_page)}
                    total={formFields.length}
                  />
                </Row>
                <br />
              </>
            )}
            <Collapse
              draggable={(formFields.length ?? 0) > 1 ? true : false}
              onMoveItem={(from, to) =>
                formFields.move(from + startIndex, to + startIndex)
              }
              onChange={handleCollapseChange}
              activeKey={[selectedCollapse]}
              accordion
              items={items}
            />
            {(formFields.length ?? 0) > ITEMS_PER_PAGE_TINY && (
              <>
                <br />
                <Row justify={'end'} align={'middle'}>
                  <Pagination
                    className="pagination"
                    defaultCurrent={page}
                    defaultPageSize={ITEMS_PER_PAGE_TINY}
                    hideOnSinglePage
                    onChange={_page => setPage(_page)}
                    total={formFields.length}
                  />
                </Row>
              </>
            )}
            <br />
            <InnerButton block icon={<PlusIcon />} onClick={handleAddField} />
          </>
        )
      }}
    </FieldArray>
  )
}
