import { ReactNode, FC } from 'react'
import EditEventIcon from '../../Icon/EditEventIcon'
import TrashIcon from '../../Icon/TrashIcon'
import { TFunction } from 'i18next'
import { CALENDAR_DEADLINE_COLORS, colorTheme } from '../../../constants/colors'
import { CALENDAR_DEADLINE_TYPES } from '../../../constants/entities'

import classNames from 'classnames'
import OpenTab from '../../Icon/OpenTab'

type CalendarTypes = {
  className: string
  date: string
  name: string
  children?: ReactNode
  description?: string
  deadlineType?: keyof typeof CALENDAR_DEADLINE_TYPES
  onEditEvent?: () => void
  onOpenEvent?: () => void
  onDeleteEvent?: () => void
  t: TFunction
}

const baseClass = 'calendar-event'

export const CalendarEvent: FC<CalendarTypes> = ({
  className,
  date,
  description,
  deadlineType,
  name,
  children,
  onEditEvent,
  onOpenEvent,
  onDeleteEvent,
  t,
}) => {
  return (
    <div
      className={classNames(`${baseClass}-wrapper`, className)}
      style={{
        outlineColor: `${deadlineType ? CALENDAR_DEADLINE_COLORS[deadlineType] : colorTheme.primaryColorGrape}`,
      }}
    >
      <div className={`${baseClass}-row ${baseClass}-date-title`}>
        <div className={`${baseClass}-date`}>{date}</div>
        {deadlineType && (
          <div className={`${baseClass}-deadline-text`}>
            {t(
              `entity.company.vat.addDeadlineForm.types.${deadlineType}`
            ).toLowerCase()}
          </div>
        )}
      </div>
      <div className={`${baseClass}-title line-clamp`}>{name}</div>
      {description && (
        <div className={`${baseClass}-text line-clamp`}>{description}</div>
      )}
      <div className={`${baseClass}-row calendar-event-footer`}>
        {children}
        <div className={`${baseClass}-icons`}>
          {onDeleteEvent && (
            <div onClick={onDeleteEvent}>
              <TrashIcon />
            </div>
          )}
          {onEditEvent && (
            <div onClick={onEditEvent}>
              <EditEventIcon />
            </div>
          )}
          {onOpenEvent && (
            <div onClick={onOpenEvent}>
              <OpenTab />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CalendarEvent
