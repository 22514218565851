import gql from 'graphql-tag'

export const getFiletypesQuery = gql`
  query getFiletypes($applicationId: ID) {
    getFiletypes(applicationId: $applicationId) {
      id
      title
    }
  }
`

export const getDocumentsTagsQuery = gql`
  query getDocumentsTags($entityId: ID, $processInstanceId: ID) {
    getDocumentsTags(entityId: $entityId, processInstanceId: $processInstanceId)
  }
`
