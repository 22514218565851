import { CSSProperties, useState, useRef, useMemo, useEffect } from 'react'
import classNames from 'classnames'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import ValidationError from '../Errors/Validation'

import ReactDOM from 'react-dom'
import PlusIcon from '../Icon/PlusIcon'
import { useOnScroll } from '../../hooks/useOnScroll'
import CloseIcon from '../Icon/CloseIcon'
import { PlaceholderInput } from './PlaceholderInput'
import { isBoolean } from 'lodash'
import { SelectInputItem, sort, textContent } from './helpers'
import { SelectRow, SelectRowMultiple } from './SelectRow'
import { SelectGroupRow } from './SelectGroupRow'
import { SelectOption } from './SelectOption'
import { LoopLoader } from '../LoopLoader/LoopLoader'

interface SelectInputProps {
  className?: string
  mode?: string
  required?: boolean
  label?: string
  style?: CSSProperties
  value?: null | string | string[]
  loading?: boolean
  name?: string
  placeholder?: string
  error?: string
  options: SelectInputItem[]
  onChange: (e: null | string | string[]) => void
  onAdd: (e: null | string | string[]) => void
  disabled?: boolean
  showSearch?: boolean
  sortingData: boolean
  filterOption: (val: null | string, option: SelectInputItem) => void
  defaultValue: null | string | string[]
  allowClear: boolean
  readonly?: boolean
}

export const SelectInput = ({
  className,
  value = null,
  label,
  required = false,
  loading,
  options,
  onChange,
  onAdd,
  style,
  error,
  disabled,
  mode,
  defaultValue,
  sortingData = true,
  placeholder,
  filterOption,
  showSearch = false,
  allowClear = false,
  readonly = false,
  ...rest
}: SelectInputProps) => {
  const props: any = rest
  const containerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [isDropdownOpen, setDatalistDisplay] = useState<boolean>(false)
  const [cmpCoordinate, setCmpCoordinate] = useState<any>(null)
  const [inputValue, setInputValue] = useState<any>(
    mode === 'tags' || mode === 'addable' ? value : null
  )

  const hasValue = useMemo(
    () =>
      typeof value !== undefined &&
      value !== null &&
      (value.length || isBoolean(value)),
    [value]
  )
  const dropdownHasValue = useMemo(
    () => (hasValue ? 'dropdown-has-value' : null),
    [hasValue]
  )

  useOnClickOutside(containerRef, async (e: any) => {
    const listClassName = 'dropdown-select__list'
    const isInOverlay =
      e.target.classList.contains(listClassName) ||
      e.target.closest(`.${listClassName}`)
    if (!isInOverlay && !containerRef.current?.contains(e.target)) {
      setDatalistDisplay(false)
      if (showSearch) {
        onAdd && (await onAdd(inputValue))
        setInputValue('')
      }
    }
  })

  useOnScroll(containerRef, () => {
    setDatalistDisplay(false)
  })

  const handleResize = () => {
    if (containerRef.current) {
      const coordinate = containerRef.current.getBoundingClientRect()
      setCmpCoordinate(coordinate)
    }
  }

  const handleClick = () => {
    if (disabled || readonly) {
      return
    }
    handleResize()
    setDatalistDisplay(prevState => !prevState)

    if (
      (mode === 'tags' || mode === 'multipletags' || showSearch) &&
      inputRef.current
    ) {
      if (isDropdownOpen) {
        inputRef.current.blur()
        if (showSearch) {
          setInputValue('')
        }
      } else {
        inputRef.current.focus()
      }
    }
  }

  const handleChangeOption = (newOption: string) => {
    if (
      (mode === 'multiple' || mode === 'multipletags') &&
      (Array.isArray(value) || !value)
    ) {
      const values = Array.isArray(value) ? [...value] : []
      const index = values.findIndex(val => val === newOption)
      index >= 0 ? values.splice(index, 1) : values.push(newOption)
      typeof onChange === 'function' && onChange(values)
    } else {
      if (mode === 'tags') {
        const option = flatOptions.find(option => option.value === newOption)
        setInputValue(option.title)
      }

      if (showSearch) {
        setInputValue('')
      }

      setDatalistDisplay(false)
      typeof onChange === 'function' && onChange(newOption)
    }
  }

  const match = (a: string, b: string) =>
    a.toLowerCase().includes(b.toLowerCase())

  const optionsFinal = useMemo(() => {
    const result = options || []
    if (mode === 'multipletags') {
      let anc = !value ? [] : Array.isArray(value) ? value : [value]
      const tags: SelectInputItem[] = anc.map(val => ({
        value: val,
        title: val,
      }))
      return [
        ...result,
        ...tags.filter(({ value }) => !result.find(res => res.value === value)),
      ]
    }
    return result
  }, [options, mode, value])

  const flatOptions = useMemo(
    () =>
      optionsFinal.reduce((acc: any, val: SelectInputItem) => {
        if (val.options) {
          return [...acc, ...val.options]
        }
        return [...acc, val]
      }, []),
    [optionsFinal]
  )

  const selectedOptions = useMemo(() => {
    if (!hasValue || !flatOptions.length) {
      return []
    }
    return flatOptions.filter(
      option =>
        Array.isArray(value) &&
        value.findIndex(val => val === option.value) >= 0
    )
  }, [flatOptions, value])

  const selectedOption = useMemo(() => {
    if (!hasValue || !flatOptions.length) {
      return null
    }
    return flatOptions.find(option => option.value === value)
  }, [flatOptions, value])

  const filterFunc = (option: SelectInputItem, value: string) =>
    (typeof filterOption === 'function' &&
      filterOption(inputValue, {
        ...option,
        title: textContent(option.title),
      })) ||
    match(textContent(option.title), value) ||
    match(option.value, value)

  const finalOptions = useMemo(() => {
    let result = optionsFinal

    const set = (
      hasValue: boolean | number,
      value: SelectInputProps['value']
    ) => {
      result = result.filter(option => {
        const string = typeof value === 'string'

        return hasValue && string
          ? option.options
            ? option.options.some(sub =>
                value && string ? filterFunc(sub, value) : true
              )
            : filterFunc(option, value)
          : true
      })
    }

    if (mode === 'tags' || mode === 'multipletags') {
      set(hasValue, value)
    } else if (showSearch) {
      set(inputValue, inputValue)
    }

    sort(sortingData, result)

    return result
  }, [optionsFinal, value, mode, inputValue, selectedOption])

  const onRemoveValue = (optionVal: string) => {
    if (
      (mode === 'multiple' || mode === 'multipletags') &&
      Array.isArray(value)
    ) {
      const values = [...value]
      const index = values.findIndex(val => val === optionVal)
      index >= 0 && values.splice(index, 1)
      typeof onChange === 'function' && onChange(values)
    }
  }

  const renderOption = ({ title, icon, isAdding }: SelectInputItem) => {
    return (
      <div className="dropdown-select-render">
        {isAdding && (
          <div className="dropdown-select-render-add">
            <PlusIcon />
          </div>
        )}
        <div>{!!icon && icon()}</div>
        <div>{title}</div>
      </div>
    )
  }

  const classes = classNames(
    'dropdown-select',
    className,
    dropdownHasValue,
    disabled && 'dropdown-select--disabled',
    mode === 'multiple' && 'dropdown-select--multiple',
    isDropdownOpen && 'dropdown-select--opened'
  )

  const ulStyle = useMemo(() => {
    if (!cmpCoordinate || !containerRef.current) {
      return
    }
    const height = containerRef.current.offsetHeight - 1

    return cmpCoordinate.top + height + 300 >= window.innerHeight
      ? {
          inset: `auto auto ${window.innerHeight - cmpCoordinate.top - 1}px ${cmpCoordinate.left}px`,
          width: `${cmpCoordinate.width}px`,
        }
      : {
          inset: `${cmpCoordinate.top + height}px auto auto ${cmpCoordinate.left}px`,
          width: `${cmpCoordinate.width}px`,
        }
  }, [cmpCoordinate])

  const finalRequired = useMemo(
    () => required || (typeof props === 'object' && props['aria-required']),
    [required, props]
  )

  const onInputBlur = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = evt.target.value
    if (mode === 'multipletags') {
      let anc = !value ? [] : Array.isArray(value) ? value : [value]
      setInputValue('')
      if (!anc.includes(newVal) && newVal.length) {
        onChange([...anc, newVal])
      }
    }
  }

  const onInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = evt.target.value
    setInputValue(newVal)
    if (mode === 'tags') {
      onChange(newVal)
    }
  }

  useEffect(() => {
    if (defaultValue && !hasValue) {
      setInputValue(defaultValue)
      onChange(defaultValue)
    }
    if (mode === 'tags') {
      if (hasValue) {
        const option = flatOptions.find(option => option.value === value)
        option && setInputValue(option.title)
      }
    }
    if (!containerRef.current) {
      return () => {}
    }
    const resizeObserver = new ResizeObserver(handleResize)
    resizeObserver.observe(containerRef.current)
    return () => resizeObserver.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (mode === 'tags' && value !== inputValue && !selectedOption) {
      setInputValue(value)
    }
  }, [value])

  return (
    <div className={classes} ref={containerRef}>
      <div
        style={style}
        className="dropdown-select__input"
        onClick={handleClick}
      >
        {mode === 'multipletags' || mode === 'multiple' ? (
          <div className="text-multiple">
            <div className="text-content text-content--multiple text-content--tags">
              {selectedOptions.map(option => (
                <SelectOption
                  key={option.value}
                  title={option.title}
                  onRemove={() => onRemoveValue(option.value)}
                />
              ))}
            </div>
            {placeholder &&
            mode === 'multipletags' &&
            (hasValue || isDropdownOpen) ? (
              <PlaceholderInput
                placeholder={placeholder}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    let anc = !value
                      ? []
                      : Array.isArray(value)
                        ? value
                        : [value]
                    if (!anc.includes(inputValue) && inputValue.length) {
                      onChange([...anc, inputValue])
                    }
                    setInputValue('')
                    e.preventDefault()
                  }
                }}
                onAddClick={() => {
                  let anc = !value ? [] : Array.isArray(value) ? value : [value]
                  if (!anc.includes(inputValue) && inputValue.length) {
                    onChange([...anc, inputValue])
                  }
                  setInputValue('')
                }}
                inputValue={inputValue}
                onBlur={onInputBlur}
                onChange={onInputChange}
              />
            ) : (
              (showSearch || mode === 'multipletags') && (
                <input
                  title="input"
                  ref={inputRef}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      if (mode === 'multipletags') {
                        let anc = !value
                          ? []
                          : Array.isArray(value)
                            ? value
                            : [value]
                        if (!anc.includes(inputValue) && inputValue.length) {
                          onChange([...anc, inputValue])
                        }
                        setInputValue('')
                      }
                      e.preventDefault()
                    }
                  }}
                  value={inputValue}
                  onBlur={onInputBlur}
                  onChange={onInputChange}
                />
              )
            )}
          </div>
        ) : mode === 'tags' || mode === 'addable' ? (
          <div className="text-multiple">
            {typeof inputValue !== 'string' && (
              <div className="text-content text-content--multiple  text-content--tags">
                {selectedOption && renderOption(selectedOption)}
              </div>
            )}
            <input
              title="input"
              ref={inputRef}
              onKeyDown={e => {
                e.key === 'Enter' && e.preventDefault()
                e.key === 'Backspace' &&
                  typeof inputValue !== 'string' &&
                  onChange(null)
              }}
              value={typeof inputValue === 'string' ? inputValue : ''}
              onChange={onInputChange}
            />
          </div>
        ) : showSearch ? (
          <>
            <input
              title="input"
              ref={inputRef}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  onAdd?.(inputValue)
                  e.preventDefault()
                }
              }}
              value={inputValue}
              onChange={onInputChange}
            />
            {!inputValue && (value || isDropdownOpen) && (
              <div className="text-content">
                {selectedOption && renderOption(selectedOption)}
              </div>
            )}
          </>
        ) : (
          <>
            {(hasValue || isDropdownOpen) && (
              <div className="text-content">
                {selectedOption && renderOption(selectedOption)}
              </div>
            )}
          </>
        )}
        <div className="dropdown-extra">
          {loading && (
            <div className="dropdown-extra-loading">{<LoopLoader />}</div>
          )}
          {allowClear && hasValue && (
            <div
              className="dropdown-extra-allowclear"
              onClick={() =>
                Array.isArray(value) ? onChange([]) : onChange(null)
              }
            >
              <CloseIcon />
            </div>
          )}
        </div>

        <label
          className={
            hasValue || isDropdownOpen
              ? 'select-label-fulfilled'
              : 'text-content'
          }
          htmlFor={label}
        >
          {finalRequired && <span className="required">*</span>}
          {label}
        </label>
      </div>

      {ReactDOM.createPortal(
        <ul
          className={classNames(
            'dropdown-select__list',
            isDropdownOpen && 'active'
          )}
          style={ulStyle}
        >
          {mode === 'multiple' || mode === 'multipletags'
            ? finalOptions.map((option, index) => (
                <>
                  {!option.options ? (
                    <SelectRowMultiple
                      key={index}
                      option={option}
                      index={index}
                      {...{
                        value,
                        handleChangeOption,
                        setDatalistDisplay,
                        renderOption,
                      }}
                    />
                  ) : (
                    <SelectGroupRow
                      key={index}
                      option={option}
                      index={index}
                      multiple
                      {...{
                        handleChangeOption,
                        renderOption,
                        value,
                        setDatalistDisplay,
                        mode,
                        showSearch,
                        sortingData,
                        filterFunc,
                        inputValue,
                        selectedOption,
                        options,
                      }}
                    />
                  )}
                </>
              ))
            : finalOptions.map((option, index) => (
                <>
                  {!option.options ? (
                    <SelectRow
                      key={index}
                      option={option}
                      index={index}
                      {...{ value, handleChangeOption, renderOption }}
                    />
                  ) : (
                    <SelectGroupRow
                      key={index}
                      option={option}
                      index={index}
                      {...{
                        handleChangeOption,
                        renderOption,
                        value,
                        mode,
                        showSearch,
                        sortingData,
                        filterFunc,
                        inputValue,
                        selectedOption,
                        options,
                      }}
                    />
                  )}
                </>
              ))}
        </ul>,
        document.body
      )}

      {error && (
        <div className="error-wrapper">
          <ValidationError error={error} />
        </div>
      )}
    </div>
  )
}
