import { useEffect, useMemo, useRef, useState } from 'react'
import ArrowRightIcon from '../Icon/ArrowRight'
import Collapse from '../Collapse/Collapse'
import { Feature, PermissionTableProps } from './PermissionTable.d'
import { PermissionHeader } from './components/PermissionHeader/PermissionHeader'
import { PermissionRow } from './components/PermissionRow'

const categoryOrder = {
  service: 0,
  workflow: 1,
  dossier: 2,
  entity: 3,
  report: 4,
  stickynote: 5,
  support: 6,
  user: 7,
  team: 8,
  role: 9,
  welcome: 10,
  email: 11,
  calendar: 12,
  platform: 13,
  subscription: 14,
}

const PermissionTable = ({
  title,
  linkText,
  href,
  headerEditable,
  creatable,
  editable,
  deletable,
  features,
  roles,
  lang,
  onChange,
  t,
}: PermissionTableProps) => {
  const [isEditingTitle, setIsEditingTitle] = useState(false)
  const permissionTableContainer = useRef<HTMLDivElement>(null)

  const rolesFiltered = useMemo(
    () =>
      (roles || [])
        .filter(({ toDelete }) => !toDelete)
        .sort((a, b) => a.sortOrder - b.sortOrder),
    [roles]
  )

  useEffect(() => {
    permissionTableContainer.current?.style.setProperty(
      '--nb-columns',
      rolesFiltered.length.toString()
    )
  }, [rolesFiltered])

  const items = useMemo(() => {
    return Object.values(features as Record<string, Feature[]>)
      .sort((a, b) => {
        const orderA =
          categoryOrder[a[0]?.category as keyof typeof categoryOrder] ??
          Number.MAX_VALUE
        const orderB =
          categoryOrder[b[0]?.category as keyof typeof categoryOrder] ??
          Number.MAX_VALUE
        return orderA - orderB
      })
      .map((features: Feature[]) => {
        return {
          title: t(`userPermissions.features.${features[0].category}.name`),
          key: features[0].category,
          content: features
            .filter(feature => feature.global === true)
            .sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0))
            .map(feature => (
              <PermissionRow
                editable={editable}
                key={feature.name}
                feature={feature}
                roles={rolesFiltered}
                setRoles={onChange}
              />
            )),
        }
      })
      .filter(item => item.content.length > 0)
  }, [features, rolesFiltered])

  return (
    <>
      {!!linkText && (
        <a className="permission-link" href={href} target="_blank">
          {linkText} <ArrowRightIcon />
        </a>
      )}

      <div className="permission-table" ref={permissionTableContainer}>
        <PermissionHeader
          roles={rolesFiltered}
          headerEditable={headerEditable}
          creatable={creatable}
          editable={editable}
          deletable={deletable}
          isEditingTitle={isEditingTitle}
          setIsEditingTitle={setIsEditingTitle}
          lang={lang}
          setRoles={onChange}
          title={title}
          t={t}
        />
        <Collapse
          accordion
          items={items.map(feature => feature)}
          onMoveItem={(from, to) => console.log(from, to)}
        />
      </div>
    </>
  )
}
export default PermissionTable
